import React, { lazy, Suspense } from "react";
// import logo from "./logo.svg";
import "./App.scss";
import { Route, Routes } from "react-router";
import {
  HomeRoute,
  SearchRoute,
  DetailRoute,
  AgreementRoute,
  ExporterRoute,
  ProductSearchRoute,
  HelpdeskRoute,
  AboutRoute,
  PreferentialTreatmentRoute,
  LoginRoute,
  PrivacyPolicyRoute,
  KeynotesRoute,
  RegisterRoute,
  DisclaimerRoute,
  TermsOfUseRoute,
  RulesOfOriginRoute,
  NewsDetailRoute,
  TradeDetailRoute,
  HelpDeskfornowRoute,
} from "./routes/route";
import Loading from "./components/common/Loading";

const Home = lazy(() => import("./pages/home"));
const Keynotes = lazy(() => import("./pages/keynotes"));
const Search = lazy(() => import("./pages/search"));
const Detail = lazy(() => import("./pages/detail"));
const Agreement = lazy(() => import("./pages/agreements"));
const NewsDetail = lazy(() => import("./pages/news-detail"));
const Exporter = lazy(() => import("./pages/exporter"));
const ProductSearch = lazy(() => import("./pages/product-search"));
const HelpDesk = lazy(() => import("./pages/helpdesk"));
const HelpDesk2 = lazy(() => import("./pages/help-desk"));
const About = lazy(() => import("./pages/about"));
const RulesOfOrigin = lazy(() => import("./pages/rules-of-origin"));
const PreferentialTreatment = lazy(
  () => import("./pages/preferential-treatment")
);
const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const TermsOfUse = lazy(() => import("./pages/terms-of-use"));
const Disclaimer = lazy(() => import("./pages/disclaimer"));
const FAQ = lazy(() => import("./pages/helpdesk/faq"));
const BasicInformation = lazy(
  () => import("./pages/helpdesk/basic-information")
);
const UserGuide = lazy(() => import("./pages/helpdesk/user-guide"));
const SiteGlossary = lazy(() => import("./pages/helpdesk/site-glossary"));
const CambodiaCustomGuides = lazy(
  () => import("./pages/helpdesk/cambodia-custom-guides")
);
const RulesofOrigin = lazy(() => import("./pages/helpdesk/rules-of-origin"));
const TradeDetail = lazy(() => import("./pages/trade-detail"));

const routes = [
  {
    path: HomeRoute,
    element: <Home />,
  },

  {
    path: SearchRoute,
    element: <Search />,
  },

  {
    path: DetailRoute,
    element: <Detail />,
  },

  {
    path: KeynotesRoute,
    element: <Keynotes />,
  },

  {
    path: AgreementRoute,
    element: <Agreement />,
  },

  {
    path: ExporterRoute,
    element: <Exporter />,
  },

  {
    path: ProductSearchRoute,
    element: <ProductSearch />,
  },

  {
    path: HelpdeskRoute,
    element: <HelpDesk />,
    children: [
      { path: "faq", element: <FAQ /> },
      { path: "basic-information", element: <BasicInformation /> },
      { path: "user-guide", element: <UserGuide /> },
      { path: "site-glossary", element: <SiteGlossary /> },
      { path: "cambodia-custom-guides", element: <CambodiaCustomGuides /> },
      { path: "rules-of-origin", element: <RulesofOrigin /> },
    ],
  },

  {
    path: HelpDeskfornowRoute,
    element: <HelpDesk2 />,
  },

  {
    path: AboutRoute,
    element: <About />,
  },

  {
    path: RulesOfOriginRoute,
    element: <RulesOfOrigin />,
  },

  {
    path: PreferentialTreatmentRoute,
    element: <PreferentialTreatment />,
  },

  {
    path: LoginRoute,
    element: <Login />,
  },

  {
    path: RegisterRoute,
    element: <Register />,
  },

  {
    path: PrivacyPolicyRoute,
    element: <PrivacyPolicy />,
  },

  {
    path: TermsOfUseRoute,
    element: <TermsOfUse />,
  },

  {
    path: DisclaimerRoute,
    element: <Disclaimer />,
  },

  {
    path: NewsDetailRoute,
    element: <NewsDetail />,
  },

  {
    path: TradeDetailRoute,
    element: <TradeDetail />,
  },
];

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        {/* <Routes>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} element={route.element} />
          ))}
        </Routes> */}

        <Routes>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} element={route.element}>
              {/* Render child routes if they exist */}
              {route.children &&
                route.children.map((child, index) => (
                  <Route
                    key={index}
                    path={child.path}
                    element={child.element}
                  />
                ))}
            </Route>
          ))}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
