const HomeRoute = "/";
const SearchRoute = "/search";
const DetailRoute = "/detail";
const AgreementRoute = "/agreements";
const ExporterRoute = "/first-time-exporter";
const ProductSearchRoute = "/product-search";
const HelpdeskRoute = "/helpdesk";
const HelpDeskfornowRoute = "/help-desk";
const AboutRoute = "/about-us";
const RulesOfOriginRoute = "/rules-of-origin";
const PreferentialTreatmentRoute = "/preferential-treatment";
const LoginRoute = "/login";
const RegisterRoute = "/register";
const PrivacyPolicyRoute = "/privacy-policy";
const TermsOfUseRoute = "/terms-of-use";
const DisclaimerRoute = "/disclaimer";
const KeynotesRoute = "/keynotes";
const NewsDetailRoute = "/news-detail";
const TradeDetailRoute = "/trade-detail";

export {
    HomeRoute,
    SearchRoute,
    DetailRoute,
    AgreementRoute,
    ExporterRoute,
    ProductSearchRoute,
    HelpdeskRoute,
    AboutRoute,
    RulesOfOriginRoute,
    PreferentialTreatmentRoute,
    LoginRoute,
    RegisterRoute,
    PrivacyPolicyRoute,
    KeynotesRoute,
    TermsOfUseRoute,
    DisclaimerRoute,
    NewsDetailRoute,
    TradeDetailRoute,
    HelpDeskfornowRoute //Plz remove it later
}