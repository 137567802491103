import React from "react";

const Loading = () => {
  return (
    <div className="test d-flex align-items-center">
      <p>Loading ...</p>
    </div>
  );
};

export default Loading;
